import React from "react";
// import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// import * as actions from "../store/actions";

const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

export const Navigator = () => {
  const loadingTemplate = (() => (
    <div>
      <strong>Loading page...</strong>
    </div>
  ))();

  const nonAuthroutes = (
    <Switch>
      <Route
        path="/"
        name="Home"
        render={(props) => <DefaultLayout {...props} />}
      />
    </Switch>
  );

  return (
    <React.Suspense fallback={loadingTemplate}>
      <BrowserRouter>{nonAuthroutes}</BrowserRouter>
    </React.Suspense>
  );
};
