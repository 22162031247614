export const SET_VALUE = "SET_VALUE";
export const SET_LOADING = "@SET_LOADING";
export const SET_ERROR = "@SET_ERROR";
export const ADD_DOMICILIARY = "@ADD_DOMICILIARY";
export const INIT_DOMICILIARIES = "@INIT_DOMICILIARIES";
export const SET_USER_PROFILE = "@ADD_ADMIN";

export const INIT_CHAT = "@INIT_CHAT";
export const ADD_MESSAGE = "@ADD_MESSAGGE";
export const ADD_CONVERSACION = "@ADD_CONVERSACION";
export const NEW_MESSAGE = "@NEW_MESSAGE";

//pedido
export const INIT_PEDIDO = "@INIT_PEDIDO";
export const TIEMPO_LLEGADA = "@TIEMPO_LLEGADA";
export const LOADING_DISPONIBLES = "@LOADING_DISPONIBLES";
export const IS_DELIVERED = "@IS_DELIVERED";
export const LOADING_RATE = "@LOADING_RATE";
export const HISTORY = "@HISTORY";
export const DOMICILIARIO_DETAIL = "@DOMICILIARIO_DETAIL";
export const SET_RATED = "@SET_RATED";
export const SET_TOKEN = "@SET_TOKEN";
