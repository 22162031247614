import "bootstrap/dist/css/bootstrap.min.css";
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
html {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*:before,
*after {
  box-sizing: inherit;
}
ul,
li,
h1,
h2,
h3,
p,
button {
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
button {
  background: transparent;
  border: 0;
  outline: 0;
}
body {
  height: 100vh;
  margin: 0 auto;
  width: 100%;
  background: #FFFFFF;
}
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

}
`;
