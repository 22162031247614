import * as actionTypes from "../actionsTypes";
import { updateObject } from "../../utils";

const initialState = {
  conversacionDomiciliary: [],
  newMessageSoporte: false,
  newMessageDomiciliario: false,
};

const initChat = (state, action) =>
  updateObject(state, {
    conversacionDomiciliary: action.payload,
  });

const addMessage = (state, action) => {
  const messages = state.conversacionDomiciliary.mensajes.items;
  return {
    ...state,
    conversacionDomiciliary: {
      ...state.conversacionDomiciliary,
      mensajes: {
        items: [...messages, action.payload],
      },
    },
  };
};

const addConversacion = (state, action) =>
  updateObject(state, {
    conversacionDomiciliary: action.payload,
  });
const newMessage = (state, action) => {
  if (action.payload.type.includes("soporte")) {
    state.newMessageSoporte = action.payload.newMessage;
  }
  if (action.payload.type.includes("domiciliario")) {
    state.newMessageDomiciliario = action.payload.newMessage;
  }
  return { ...state };
};
const chat = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_MESSAGE:
      return addMessage(state, action);
    case actionTypes.INIT_CHAT:
      return initChat(state, action);
    case actionTypes.ADD_CONVERSACION:
      return addConversacion(state, action);
    case actionTypes.NEW_MESSAGE:
      return newMessage(state, action);
    default:
      return state;
  }
};

export default chat;
