import { GlobalStyle } from "./globalStyles/GlobalStyles";
import { Provider } from "react-redux";
import { Navigator } from "./navigation";
import Amplify from "aws-amplify";
import awsconfigure from "./aws-exports";
import store from "./store/store";

Amplify.configure(awsconfigure);

function App() {
  const desativateLogs = () => {
    if (!process.env.NODE_ENV.includes("development")) {
      window.console.log = () => {};
      window.console.warn = () => {};
      window.console.error = () => {};
    }
  };
  return (
    <Provider store={store}>
      {desativateLogs()}
      <GlobalStyle />
      <Navigator />
    </Provider>
  );
}

export default App;
