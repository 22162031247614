import { combineReducers } from "redux";
import chat from "./chat";
import general from "./general";
import pedido from "./pedido";

const appReducers = combineReducers({
  chat,
  general,
  pedido,
});

const rootReducer = (state, action) => {
  let reducers = appReducers(state, action);
  return reducers;
};

export default rootReducer;
