/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id:
    "us-east-1:03064923-f1f3-4358-9af7-fc52e80824c6",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_qbbqG7Kn7",
  aws_user_pools_web_client_id: "441fj91blkjh35otsa3mi4a2pr",
  oauth: {},
  aws_cognito_login_mechanisms: ["EMAIL"],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS",
      "REQUIRES_UPPERCASE",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_cloud_logic_custom: [
    {
      name: "apiheregeocoding",
      endpoint: "https://4h5lazlkuf.execute-api.us-east-1.amazonaws.com/pro",
      region: "us-east-1",
    },
    {
      name: "apihereReverse",
      endpoint: "https://4h5lazlkuf.execute-api.us-east-1.amazonaws.com/pro",
      region: "us-east-1",
    },
    {
      name: "apiHereTourPlanning",
      endpoint: "https://4h5lazlkuf.execute-api.us-east-1.amazonaws.com/pro",
      region: "us-east-1",
    },
    {
      name: "apiRestLambdaRdsDepartamento",
      endpoint: "https://ew6xtnrflk.execute-api.us-east-1.amazonaws.com/pro",
      region: "us-east-1",
    },
    {
      name: "apiRestRdsCuidad",
      endpoint: "https://ew6xtnrflk.execute-api.us-east-1.amazonaws.com/pro",
      region: "us-east-1",
    },
    {
      name: "apiRestLambdaRdsEmpresa",
      endpoint: "https://ew6xtnrflk.execute-api.us-east-1.amazonaws.com/pro",
      region: "us-east-1",
    },
    {
      name: "ApiRestLambdaEstadosPedidos",
      endpoint: "https://ew6xtnrflk.execute-api.us-east-1.amazonaws.com/pro",
      region: "us-east-1",
    },
    {
      name: "apiRestLambdaRdsEstadoUsuario",
      endpoint: "https://ew6xtnrflk.execute-api.us-east-1.amazonaws.com/pro",
      region: "us-east-1",
    },
    {
      name: "apiRestLambdaUnidadMedidas",
      endpoint: "https://ew6xtnrflk.execute-api.us-east-1.amazonaws.com/pro",
      region: "us-east-1",
    },
    {
      name: "apiRestLambdaCadena",
      endpoint: "https://ew6xtnrflk.execute-api.us-east-1.amazonaws.com/pro",
      region: "us-east-1",
    },
    {
      name: "apiRestLambdaPuntosVentas",
      endpoint: "https://ew6xtnrflk.execute-api.us-east-1.amazonaws.com/pro",
      region: "us-east-1",
    },
    {
      name: "apiRestLambdaRoles",
      endpoint: "https://ew6xtnrflk.execute-api.us-east-1.amazonaws.com/pro",
      region: "us-east-1",
    },
    {
      name: "apiRestLambdaUsuario",
      endpoint: "https://ew6xtnrflk.execute-api.us-east-1.amazonaws.com/pro",
      region: "us-east-1",
    },
    {
      name: "apiRestLambdaPedidos",
      endpoint: "https://ew6xtnrflk.execute-api.us-east-1.amazonaws.com/pro",
      region: "us-east-1",
    },
    {
      name: "apiRestLambdaDetalleDomiciliario",
      endpoint: "https://ew6xtnrflk.execute-api.us-east-1.amazonaws.com/pro",
      region: "us-east-1",
    },
  ],
  aws_appsync_graphqlEndpoint:
    "https://4fezztzxsncylfnxeq55ec65ay.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-wzxanf274vaapbbctnxipgl6im",
  aws_user_files_s3_bucket: "storage205404-pro",
  aws_user_files_s3_bucket_region: "us-east-1",
};

export default awsmobile;
