export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const svgMarkup = `<svg width="25" height="25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.875 10.417c0 7.291-9.375 13.541-9.375 13.541s-9.375-6.25-9.375-13.541a9.375 9.375 0 0 1 18.75 0Z" fill="#007D32" stroke="#075325" stroke-linecap="round" stroke-linejoin="round"/><path d="M12.5 13.542a3.125 3.125 0 1 0 0-6.25 3.125 3.125 0 0 0 0 6.25Z" stroke="#EEE" stroke-linecap="round" stroke-linejoin="round"/></svg>`;
export const svgMarkupEnd = `<svg width="25" height="25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.875 10.417c0 7.291-9.375 13.541-9.375 13.541s-9.375-6.25-9.375-13.541a9.375 9.375 0 0 1 18.75 0Z" fill="red" stroke="#075325" stroke-linecap="round" stroke-linejoin="round"/><path d="M12.5 13.542a3.125 3.125 0 1 0 0-6.25 3.125 3.125 0 0 0 0 6.25Z" stroke="#EEE" stroke-linecap="round" stroke-linejoin="round"/></svg>`;
export const svgMarkupDomiciliario = `<svg width="25" height="25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.875 10.417c0 7.291-9.375 13.541-9.375 13.541s-9.375-6.25-9.375-13.541a9.375 9.375 0 0 1 18.75 0Z" fill="yellow" stroke="#075325" stroke-linecap="round" stroke-linejoin="round"/><path d="M12.5 13.542a3.125 3.125 0 1 0 0-6.25 3.125 3.125 0 0 0 0 6.25Z" stroke="#EEE" stroke-linecap="round" stroke-linejoin="round"/></svg>`;

export const findState = (stateHistory = [], stateId) => {
  return stateHistory.find((e) => e.estado_id === stateId);
};
