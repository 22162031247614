import { updateObject } from "../../utils";
import * as actionTypes from "../actionsTypes";

const initialState = {
  error: null,
  loading: false,
};

const setError = (state, action) => {
  return updateObject(state, {
    error: action.error,
  });
};

const setLoading = (state, action) => {
  return updateObject(state, {
    loading: action.payload,
  });
};

const general = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ERROR:
      return setError(state, action);
    case actionTypes.SET_LOADING:
      return setLoading(state, action);
    default:
      return state;
  }
};

export default general;
