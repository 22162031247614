import * as actionTypes from "../actionsTypes";
import { updateObject } from "../../utils";

const initialState = {
  pedido: {},
  tiempoLLegada: undefined,
  loading: false,
  isDelivered: false,
  loadingRate: false,
  history: [],
  domiciliarioDetalle: undefined,
  isRated: false,
  token: undefined,
};
const setLoading = (state, action) => {
  return updateObject(state, {
    loading: action.payload,
  });
};
const initPedido = (state, action) =>
  updateObject(state, {
    pedido: action.payload,
  });
const setDelivered = (state, action) => {
  return updateObject(state, {
    isDelivered: action.payload,
  });
};
const setRated = (state, action) => {
  return updateObject(state, {
    isRated: action.payload,
  });
};
const loadingRate = (state, action) => {
  return updateObject(state, {
    loadingRate: action.payload,
  });
};
const TiempoLlegada = (state, action) => {
  return updateObject(state, {
    tiempoLLegada: action.payload,
  });
};
const setToken = (state, action) => {
  return updateObject(state, {
    token: action.payload,
  });
};
const setDomiciliarioDetalle = (state, action) => {
  return updateObject(state, {
    domiciliarioDetalle: action.payload,
  });
};

const setHistory = (state, action) => {
  return updateObject(state, {
    history: action.payload,
  });
};

const pedido = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INIT_PEDIDO:
      return initPedido(state, action);
    case actionTypes.TIEMPO_LLEGADA:
      return TiempoLlegada(state, action);
    case actionTypes.LOADING_DISPONIBLES:
      return setLoading(state, action);
    case actionTypes.IS_DELIVERED:
      return setDelivered(state, action);
    case actionTypes.LOADING_RATE:
      return loadingRate(state, action);
    case actionTypes.HISTORY:
      return setHistory(state, action);
    case actionTypes.DOMICILIARIO_DETAIL:
      return setDomiciliarioDetalle(state, action);
    case actionTypes.SET_RATED:
      return setRated(state, action);
    case actionTypes.SET_TOKEN:
      return setToken(state, action);
    default:
      return state;
  }
};

export default pedido;
